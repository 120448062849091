import React from 'react';

import classnames from 'classnames';
import KeywordSummaryMobile from '@seo-frontend-components/keyword-summary-mobile';

import { useStaticProps } from '../../../components/context/static-props';
import {
  namespace,
  expandablePropsSize,
  expandablePropsColor,
  expandablePropsDefaultHeightMobile,
  polycardMobile,
  seoKeywordSummaryInterventionPropTypes,
  preloadImage,
} from './constants';
import withComponentHandler from '../../../hocs/with-component-handler';
/**
 * @typedef {Object} SeoKeywordSummaryInterventionMobileProps
 * @property {string} keyword - La palabra clave asociada a la intervención.
 * @property {string} summary - El resumen de la intervención.
 * @property {string} alt_image - El texto alternativo para la imagen.
 * @property {string} image_url - La URL de la imagen asociada a la intervención.
 * @property {string} expansion_text - El texto para expandir la descripción.
 * @property {string} contraction_text - El texto para contraer la descripción.
 */

/**
 * Componente que renderiza una intervención SEO con resumen de palabra clave para dispositivos móviles.
 *
 * @param {SeoKeywordSummaryInterventionMobileProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene la intervención SEO.
 */
const SeoKeywordSummaryIntervention = ({
  keyword,
  summary,
  alt_image,
  image_url,
  expansion_text,
  contraction_text,
}) => {
  const { jsDisabled = false, country } = useStaticProps();
  const { andesLocale } = country;

  const summaryProps = {
    jsDisabled,
    title: keyword,
    description: summary,
    image: {
      altImage: alt_image,
      imageUrl: image_url,
      fallbackImageUrl: image_url,
      preload: preloadImage,
    },
    expandableProps: {
      expansionText: expansion_text,
      contractionText: contraction_text,
      size: expandablePropsSize,
      defaultHeight: expandablePropsDefaultHeightMobile,
      color: expandablePropsColor,
    },
    locale: andesLocale,
  };

  const containerClassNames = classnames(polycardMobile, `${namespace}__container`);

  return (
    <div className={containerClassNames}>
      <KeywordSummaryMobile {...summaryProps} />
    </div>
  );
};

SeoKeywordSummaryIntervention.propTypes = {
  ...seoKeywordSummaryInterventionPropTypes,
};

export default withComponentHandler(SeoKeywordSummaryIntervention, {
  componentName: 'SeoKeywordSummaryInterventionMobile',
});
