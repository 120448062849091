import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';

const namespace = 'ui-row-item__distance';

const Distance = ({ distance, className }) => (
  <div className={classnames(namespace, className)}>
    {distance}
  </div>
);

Distance.propTypes = {
  className: string,
  distance: string.isRequired,
};

Distance.defaultProps = {
  className: null,
};

export default Distance;
