import React from 'react';
import classnames from 'classnames';
import { number, string, elementType } from 'prop-types';
import useWindowsResize from '../../hooks/use-element-resize';

const namespace = 'ui-search-coupon';

const splitText = (length, levels, words = []) => {
  if (!words.length) {
    return levels;
  }

  const word = words[0];

  const nlevels = levels.length;

  const split = nlevels ? levels[nlevels - 1] : '';

  if (nlevels && split && split.length + word.length + 1 <= length) {
    levels[nlevels - 1] = `${split} ${word}`;
  } else {
    levels.push(word);
  }

  return splitText(length, levels, words.slice(1, words.length));
};

const getSplitText = (text, characterSize, maxWidth) => {
  if (!text) {
    return [];
  }

  const words = text.split(' ');
  const characters = Math.floor(maxWidth / characterSize);

  return splitText(characters, [], words);
};

const splitContent = (width, label) => {
  const characterSize = 6.3;
  const characterCount = 6;
  return getSplitText(label, characterSize, width - characterSize * characterCount);
};

const getWordsForLabel = (textsForPills) => Array.from(textsForPills).filter(text => text !== '');

const fitPriceInText = (text) => text.replace(/[\])}[{(]/g, '');


const Coupon = ({ label, background, color, contentWidth, deviceType, promotionIcon: IconComponent }) => {
  const elementRef = React.useRef(null);
  const { width: containerWidth } = useWindowsResize(elementRef, contentWidth);
  const textsForPills = splitContent(containerWidth, label);
  const splitWords = getWordsForLabel(textsForPills);
  const isMobile = deviceType !== 'desktop';

  return (
    <div ref={elementRef} className="container-promotion">
      {splitWords.map((word, index) => (
        <div
          className={classnames(namespace, {
            'label-padding-mobile': isMobile,
            'label-padding-desktop': !isMobile,
          })}
          style={{ backgroundColor: background }}
        >
          <div
            className={classnames(`${namespace}__label`, {
              'label-text-mobile': isMobile,
              'label-text-desktop': !isMobile,
            })}
            style={{ color }}
          >
            { index === 0 && (
            <div className={classnames({
              [`${namespace}__label__icon-mobile`]: isMobile,
              [`${namespace}__label__icon`]: !isMobile,
            })}
            >
              <IconComponent />
            </div>
            )}
            {fitPriceInText(word)}
          </div>
        </div>
      ))}
    </div>
  );
};

Coupon.propTypes = {
  background: string.isRequired,
  color: string.isRequired,
  contentWidth: number.isRequired,
  deviceType: string.isRequired,
  label: string.isRequired,
  promotionIcon: elementType.isRequired,
};

export default Coupon;
