import React from 'react';

import getIcon from '../../icons';
import { seoTopKeywordsInterventionPropTypes } from './propTypes';
import TextIconCarousel from '../filter-intervention/text-icon-carousel/mobile';
import withComponentHandler from '../../../hocs/with-component-handler';
/**
 * @typedef {Object} SeoTopKeywordsInterventionProps
 * @property {string} title - El título de la intervención.
 * @property {Object[]} keywords - Las palabras clave asociadas a la intervención.
 * @property {string} keywords[].id - El identificador único de la palabra clave.
 * @property {string} keywords[].label - El texto de la palabra clave.
 * @property {string} keywords[].url - El enlace asociado a la palabra clave.
 */

/**
 * Componente que renderiza una intervención SEO con las palabras clave principales para dispositivos móviles.
 *
 * @param {SeoTopKeywordsInterventionProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene la intervención SEO con las palabras clave principales.
 */
const SeoTopKeywordsIntervention = ({ keywords, title }) => {
  const namespace = 'ui-search-seo-top-keywords-intervention';

  return (
    <div className={`${namespace}__container`}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      <TextIconCarousel tags={keywords} Icon={() => getIcon({ iconId: 'search' })} />
    </div>
  );
};

SeoTopKeywordsIntervention.propTypes = {
  ...seoTopKeywordsInterventionPropTypes,
};

export default withComponentHandler(SeoTopKeywordsIntervention, { componentName: 'SeoTopKeywordsIntervention' });
