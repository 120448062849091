import React from 'react';
import { string, shape, bool } from 'prop-types';
import classnames from 'classnames';
import VariationsPicker from './variations-picker';

const namespace = 'ui-row-variations';

const Variations = ({ className, label, variations_pickers, preload, lowEnd }) => (
  <div className={classnames(namespace, className)}>
    { variations_pickers && <VariationsPicker variations_pickers={variations_pickers} preload={preload} lowEnd={lowEnd} /> }
    { label && <p>{label}</p> }
  </div>
);

Variations.propTypes = {
  className: string,
  label: string,
  preload: bool,
  variations_pickers: shape().isRequired,
  lowEnd: bool,
};

Variations.defaultProps = {
  className: null,
  label: null,
  preload: null,
  lowEnd: null,
};

export default Variations;
