import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import ItemHighlight from '../highlight/highlight-label';

const namespace = 'ui-row-rebates';

const splitText = (length, levels, words = []) => {
  if (!words.length) {
    return levels;
  }

  const word = words[0];

  const nlevels = levels.length;

  const split = nlevels ? levels[nlevels - 1] : '';

  if (nlevels && split && split.length + word.length + 1 <= length) {
    levels[nlevels - 1] = `${split} ${word}`;
  } else {
    levels.push(word);
  }

  return splitText(length, levels, words.slice(1, words.length));
};

const getSplitText = (text, characterSize, maxWidth) => {
  if (!text) {
    return [];
  }

  const words = text.split(' ');
  const characters = Math.floor(maxWidth / characterSize);

  return splitText(characters, [], words);
};

const Rebates = ({ rebates }) => {
  let rebatesContent = [];
  const contentWidth = 150;

  const splitRebate = (rebate, width, characterSize) => {
    if (rebate.highlight && width) {
      const highlight = {};

      if (rebate.highlight.background && rebate.highlight.type === 'pricing_rebates') {
        const { background } = rebate.highlight;

        highlight.label = {
          ...rebate.highlight.label,
          background,
        };
        highlight.background = undefined;
      }
      const label = rebate.highlight ? rebate.highlight.label : null;
      const splits = getSplitText(label.text, characterSize, width);
      highlight.pills = splits.map(split => ({ ...label, text: split }));

      return {
        ...rebate,
        highlight: {
          ...rebate.highlight,
          ...highlight,
        },
      };
    }
    return rebate;
  };

  const splitRebates = width => {
    const characterSize = 6.3;
    rebatesContent = rebates.map(rebate => splitRebate(rebate, width, characterSize));
  };

  const renderItemHighlight = rebate => rebate.highlight
  && rebate.highlight.pills
  && rebate.highlight.pills.map(pill => (
    <ItemHighlight
      {...rebate.highlight}
      label={pill}
      className={`${namespace}-rebate__pill`}
    />
  ));

  splitRebates(contentWidth);

  return (
    <div className={`${namespace}`}>
      {rebatesContent.map(rebate => (
        <div className={`${namespace}-rebate`}>
          {renderItemHighlight(rebate)}
          {rebate.label && rebate.label.text && (
            <span
              style={rebate.label.color ? { color: rebate.label.color } : {}}
              className={`${
                rebate.highlight.label.text.length > 15
                  ? `${namespace}-rebate__text_nl`
                  : `${namespace}-rebate__text`
              }`}
            >
              {rebate.label.text}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

Rebates.propTypes = {
  rebates: arrayOf(
    shape({
      highlight: shape({
        label: shape({
          text: string.isRequired,
          color: string.isRequired,
          background: string.isRequired,
        }).isRequired,
      }).isRequired,
      label: shape({ text: string }),
    }),
  ).isRequired,
};

export default Rebates;
