import React from 'react';
import classnames from 'classnames';
import { arrayOf, shape, string } from 'prop-types';
import StyledLabel from '../styled-label/styled-label';
import ComposedLabelValue from './composed-label-value';
import { TYPES_TEXT } from '../utils/constants';

const GENERIC_KEY_PREFIXES = {
  TOP_COLUMN: 'TOP',
  LEFT_COLUMN: 'LEFT',
  RIGHT_COLUMN: 'RIGHT',
  MODAL_COMPONENTS: 'MODAL_COMPONENTS',
};

const namespace = 'ui-search-composed-label';

const PLACEHOLDER_FORMAT_REGEX = /\{\w+\}/gi;
const PLACEHOLDER_DELIMITERS_REGEX = /{|}/;

const mapWithKey = (currentIndex, components, keyPrefix) => components.map(
    (component) => component && React.cloneElement(component, { key: `${keyPrefix}-${currentIndex}` }),
  );

const ComposedLabel = ({ className, text, values, ...props }) => {
  const componentsToRender = [];
  const componentsTypeText = [];

  let currentIndex = 0;
  let match = PLACEHOLDER_FORMAT_REGEX.exec(text);

  while (match !== null) {
    const matchIndex = match.index;
    const placeHolder = match[0];
    const valueKey = placeHolder.split(PLACEHOLDER_DELIMITERS_REGEX)[1];
    const normalTextToRender = matchIndex > 0 && text.substring(currentIndex, matchIndex);

    currentIndex = matchIndex + placeHolder.length;

    if (normalTextToRender) {
      componentsToRender.push(
        <StyledLabel
          className={`${namespace}__normal-text`}
          text={normalTextToRender}
          {...props}
        />,
      );
    }

    const valueProps = values && values.find((value) => value.key === valueKey);
    if (valueProps) {
      const composedLabel = (
        <ComposedLabelValue className={`${namespace}__value`} {...valueProps} />
      );

      if (valueProps.type !== TYPES_TEXT.ICON) {
        componentsTypeText.push(composedLabel);
      } else {
        componentsToRender.push(composedLabel);
      }
    }

    match = PLACEHOLDER_FORMAT_REGEX.exec(text);
  }

  componentsToRender.push(
    <span className="group-text" {...props}>
      {componentsTypeText}
    </span>,
  );

  if (currentIndex < text.length) {
    componentsToRender.push(
      <StyledLabel
        className={`${namespace}__normal-text`}
        text={text.substring(currentIndex)}
        {...props}
      />,
    );
  }

  return (
    <StyledLabel
      className={classnames(namespace, className)}
      text={
        currentIndex > 0
          ? mapWithKey(
              currentIndex,
              componentsToRender,
              GENERIC_KEY_PREFIXES.MODAL_COMPONENTS,
            )
          : text
      }
      {...props}
    />
  );
};

ComposedLabel.propTypes = {
  className: string,
  text: string,
  values: arrayOf(
    shape({
      key: string,
      type: string,
      text: string,
      font_weight: string,
      color: string,
    }),
  ),
};

ComposedLabel.defaultProps = {
  className: null,
  text: '',
  values: [],
};

export default ComposedLabel;
