import React from 'react';
import { node, string, func, bool } from 'prop-types';
import classnames from 'classnames';

const NO_FOLLOW_SPONSORED = 'nofollow,sponsored';

const namespace = 'ui-row-link';

const Link = ({
  children,
  className,
  href,
  title,
  role,
  onClick,
  isAd,
}) => (
  <a
    href={href}
    className={classnames(className, namespace)}
    title={title}
    role={role}
    onClick={onClick}
    rel={isAd ? NO_FOLLOW_SPONSORED : null}
  >
    {children}
  </a>
);

Link.propTypes = {
  children: node.isRequired,
  className: string,
  href: string,
  role: string,
  title: string,
  onClick: func,
  isAd: bool,
};

Link.defaultProps = {
  className: '',
  href: '',
  title: null,
  role: null,
  onClick: null,
  isAd: false,
};

export default Link;
