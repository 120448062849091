import React from 'react';
import { string, shape } from 'prop-types';
import ClassNames from 'classnames';
import ComposedLabel from '../../composed-label/composed-label';

const namespace = 'ui-row-item__title';

const Title = ({
  className,
  title,
  compats,
}) => (
  <h2 className={ClassNames(namespace, { [`${className}`]: className, [`${namespace}-compats`]: compats })}>
    {title}
    {compats && (<ComposedLabel {...compats} className={`${namespace}__compats-label`} />)}
  </h2>
);

Title.propTypes = {
  className: string,
  title: string.isRequired,
  compats: shape({}),
};

Title.defaultProps = {
  className: null,
  compats: null,
};

export default Title;
