import React from 'react';

import Image from 'nordic/image';
import classnames from 'classnames';
import get from 'lodash/get';
import has from 'lodash/has';
import PillsBuilder from '../../../pills-builder';
import Pill from './pill/Pill';
import Action from './actions/action';
import ItemTitle from './card/title';
import ItemBrand from './card/brand';
import ItemSpotlightGroup from './card/spotlight';
import ItemPrice from './card/price';
import ItemShippingGroup from './card/shipping';
import ItemBookmark from './bookmark/bookmark.mobile';
import ItemAttributes from './card/attributes/attributes.mobile';
import ItemDistance from './card/distance';
import ItemVariations from './card/variations/variations.mobile';
import ItemOfficialStore from './card/official-store/official-store-label';
import ItemInstallments from './card/installments/installments';
import ItemInfoHighlight from './card/highlight/highlight-label';
import ItemValuePropositions from './card/value-proposition/value-propositions';
import ItemVerticalHighlight from './card/vertical-highlight/vertical-highlight';
import ItemMeliCoin from './card/melicoin';
import Reviews from './reviews/reviews.mobile';
import Link from './link';
import Group from './card/group/group';
import PdsBestPrice from './card/pds/pds-best-price.mobile';
import PdsOptions from './card/pds/pds-options.mobile';
import PromiseMessage from './card/promise-message/promise-message';
import ItemRebates from './card/rebates';
import PriceDetails from './card/price-details/price-details';
import Wrapper from './wrapper';
import WinnerAlternatives from './card/winner-alternatives/winner-alternatives';

import Card, { CardContent } from'@andes/card';

const namespace = 'ui-row-card';

const VERTICAL_TYPE_SRV = 'SRV';
const VERTICAL_TYPE_CORE = 'CORE';
const VERTICAL_TYPE_MOT = 'MOT';
const VERTICAL_TYPE_RES = 'RES';
const ESTIMATED_CONTENT_WIDTH = 180;

const configPreload = { stack: 3 };

function renderPriceDetails(item, deviceType, country, currencies) {
  if (item.price_details) {
    return (
      <PriceDetails
        priceDetails={item.price_details}
        deviceType={deviceType}
        country={country}
        currencies={currencies}
      />
    );
  }
  return null;
}

const renderSpotlight = (item, position = null) => {
  const spotlight = get(item, 'spotlight', []);
  const filteredSpotlight = spotlight.filter((s) => (position ? s.position === position : !s.position));

  return filteredSpotlight.length > 0 ? <ItemSpotlightGroup spotlight={filteredSpotlight} /> : null;
};

function renderPrice(item, deviceType, country, currencies) {
  if (!has(item, 'price.amount')) {
    return  has(item, 'price.label') ?
      <div className="ui-row-item__price-container">
        <div className="ui-row-item__price-label">{item.price.label.text}</div>
        {renderPriceDetails(item, deviceType, country, currencies)}
      </div> : null;
  }

  const isDiscountLabelBelow = item?.price?.discount_label?.is_label_below;

  return (
    <div className={classnames("ui-row-item__price-container",{'ui-row-item__discount--below': isDiscountLabelBelow })}>
      <ItemPrice
        {...item.price}
        discountSource={item.discount_source}
        from={item.from}
        deviceType={deviceType}
        country={country}
        currencies={currencies}
        showOriginalValue={!!item.price.original_price}
        originalPriceSize='x-tiny'
      />
      {renderPriceDetails(item, deviceType, country, currencies)}
    </div>
  );
}

const renderMediaTagGroup = ({ media }) => media && (
  <Group noSeparator name="media-tag">
    <ItemInfoHighlight {...media} />
  </Group>
);

const renderMeliCoinGroup = (item) => item.melicoin && <ItemMeliCoin {...item.melicoin} />;

const renderPromiseLabel = (item) => item.manufacturing_time && (
  <PromiseMessage manufacturingTime={item.manufacturing_time} />
);

const renderVerticalHighlightGroup = (item) => (item.vertical_highlight
  || (item.value_propositions && item.value_propositions.length > 0)) && (
    <Group name="vertical-highlight">
      {item.vertical_highlight && (
        <ItemVerticalHighlight {...item.vertical_highlight} />
      )}
      {item.value_propositions && item.value_propositions.length > 0 && (
        <ItemValuePropositions propositions={item.value_propositions} />
      )}
    </Group>
  );

function renderSrvContent(item, layout, deviceType, country, currencies) {
  return (
    <div className={`${namespace}__content ui-row-card__content--srv`}>
      <Group className="ui-row-item__group--title">
        <ItemTitle title={item.title} compats={item.title_compats} />
      </Group>
      {renderSpotlight(item)}
      <Group>{renderPrice(item, deviceType, country, currencies)}</Group>
      {renderSpotlight(item, 'bottom')}
      <Group>
        {item.descriptions && (
          <ItemAttributes
            attributes={item.descriptions}
            className="ui-row-item__attributes ui-row-item__attributes--srv"
          />
        )}
      </Group>
    </div>
  );
}

const renderCouponPill = ({ promotions }, deviceType) => (
  promotions && promotions.length > 0 && promotions.map((element) => (
    <Pill key={element.id} element={element} deviceType={deviceType} />
  ))
);

const renderPillsBuilder = ({ data, cssClass }) => <PillsBuilder pills={data} cssClass={cssClass} />;

const renderPills = ({ pills }) => pills && renderPillsBuilder({ data: pills });

const renderBottomPills = ({ bottom_pills }) => bottom_pills && renderPillsBuilder({
  data: bottom_pills,
  cssClass: 'ui-pb--bottom-pills',
});


const renderBottomAlternatives = (item, country) => {
  const classNameGroup = 'ui-row-item__winner-bottom-alternatives-container';
  return has(item, 'product.bottom_alternatives')
    && (
      <Group className={classNameGroup}>
        <WinnerAlternatives
          itemId={item.id}
          alternativeProp="bottom_alternatives"
          alternatives={item.product.bottom_alternatives}
          country={country}
        />
      </Group>
    );
};

const renderPriceOrWinnerAlternatives = (item, deviceType, country, currencies) => {
  if (has(item, 'product.alternatives')) {
    return (
      <Group className="ui-row-item__winner-alternatives-container">
        <WinnerAlternatives itemId={item.id} alternativeProp="alternatives" alternatives={item.product.alternatives} country={country} />
      </Group>
    );
  }

  const isDiscountLabelBelow = item?.price?.discount_label?.is_label_below;
  return (
    <Group noSeparator={isDiscountLabelBelow}>
      {item.price && renderPrice(item, deviceType, country, currencies)}
      {item.installments && (
      <ItemInstallments
        installments={item.installments}
        country={country}
        currencies={currencies}
        price={item.price}
      />
        )}
    </Group>
  );
};

function renderCoreContent(
  item,
  layout,
  deviceType,
  country,
  currencies,
  preload,
  lowEnd,
) {
  const itemHighlightInfo = get(item, 'item_highlight', get(item, 'highlight'));
  const itemTags = get(item, 'tags');
  const className = classnames(
    `${namespace}__content`,
    `${namespace}__content--core`,
    { [`${namespace}__content--advertisement`]: item.is_ad },
  );

  const showItemOfficalStore = item.official_store
    && (item.official_store.verbose_text?.text || item.official_store.text?.text);

  return (
    <div className={className}>
      {itemHighlightInfo && (
        <Group>
          <ItemInfoHighlight {...itemHighlightInfo} />
        </Group>
      )}
      {item.brand_discoverability && (
        <Group className="ui-row-item__group--brand-discoverability">
          <ItemBrand brand={item.brand_discoverability.text} />
        </Group>
      )}
      <Group className="ui-row-item__group--title">
        <ItemTitle title={item.title} compats={item.title_compats} />
        {item.reviews && (
          <Reviews
            rating={item.reviews.rating_average}
            stars={item.reviews.rating_stars}
            formattedTotal={item.reviews.formatted_total_label || item.reviews.formatted_total}
            formattedAverage={item.reviews.formatted_rating_average || item.reviews.rating_average_formatted}
            accessibilityText={item?.reviews?.accessibility_text || item.reviews.alt_text}
          />
        )}
      </Group>
      {renderSpotlight(item)}
      {renderPriceOrWinnerAlternatives(item, deviceType, country, currencies)}
      {renderMeliCoinGroup(item)}
      {renderCouponPill(item, deviceType)}
      <Group className="ui-row-item__group--rebates">
        {item.rebates && item.rebates.length > 0 && (
          <ItemRebates rebates={item.rebates} />
        )}
      </Group>
      {renderPills(item)}
      <ItemShippingGroup
        shipping={item.shipping}
        tags={itemTags}
        contentWidth={ESTIMATED_CONTENT_WIDTH}
      />
      {renderSpotlight(item, 'bottom')}
      {renderPromiseLabel(item)}
      {renderVerticalHighlightGroup(item)}
      <Group>
        {(item.variations || item.variations_picker) && (
          <ItemVariations
            label={item.variations && item.variations.label}
            variations_pickers={item.variations_picker}
            preload={preload}
            lowEnd={lowEnd}
          />
        )}
        {renderBottomPills(item)}
        {showItemOfficalStore && (
          <ItemOfficialStore
            label={
              item.verboseLabels
                ? item.official_store.verbose_text
                : item.official_store.text
            }
            icon={item.official_store.has_official_store_icon}
          />
        )}
      </Group>
      {renderBottomAlternatives(item, country)}
      {item.pds_info && (
        <Group>
          <PdsBestPrice
            {...item.pds_info.best_price}
            currencies={currencies}
            deviceType={deviceType}
            country={country}
          />
          <PdsOptions {...item.pds_info.label} />
        </Group>
      )}
    </div>
  );
}

function renderShopsContent(
  item,
  layout,
  deviceType,
  country,
  currencies,
  preload,
  lowEnd,
) {
  const itemTags = get(item, 'tags');
  const className = classnames(
    `${namespace}__content`,
    `${namespace}__content--core`,
    { [`${namespace}__content--advertisement`]: item.is_ad },
  );

  return (
    <div className={className}>
      {renderPills(item)}
      <Group className="ui-row-item__group--title">
        <ItemTitle title={item.title} compats={item.title_compats} />
      </Group>
      {renderSpotlight(item)}
      {renderPriceOrWinnerAlternatives(item, deviceType, country, currencies)}
      {renderCouponPill(item, deviceType)}
      <Group className="ui-row-item__group--rebates">
        {item.rebates && item.rebates.length > 0 && (
          <ItemRebates rebates={item.rebates} />
        )}
      </Group>
      {renderSpotlight(item, 'bottom')}
      <ItemShippingGroup
        shipping={item.shipping}
        tags={itemTags}
        contentWidth={ESTIMATED_CONTENT_WIDTH}
      />
      {renderPromiseLabel(item)}
      {renderVerticalHighlightGroup(item)}
      <Group>
        {(item.variations || item.variations_picker) && (
          <ItemVariations
            label={item.variations && item.variations.label}
            variations_pickers={item.variations_picker}
            preload={preload}
            lowEnd={lowEnd}
          />
        )}
        {item.official_store && (
          <ItemOfficialStore
            label={
              item.verboseLabels
                ? item.official_store.verbose_text
                : item.official_store.text
            }
            icon={item.official_store.has_official_store_icon}
          />
        )}
      </Group>
      {renderBottomPills(item)}
    </div>
  );
}

function renderMotContent(item, layout, deviceType, country, currencies) {
  const itemHighlightInfo = get(item, 'good_price');
  const itemHighlightTagInfo = get(item, 'highlight');
  return (
    <div className={`${namespace}__content ${namespace}__content--mot`}>
      {itemHighlightTagInfo && (
        <Group className="ui-row-item__group--verified-vehicles">
          <ItemInfoHighlight {...itemHighlightTagInfo} />
        </Group>
      )}
      <Group className="ui-row-item__group--title">
        <ItemTitle title={item.title} compats={item.title_compats} />
      </Group>
      {renderSpotlight(item)}
      <Group className="ui-row-item__group--price">
        {renderPrice(item, deviceType, country, currencies)}
        {itemHighlightInfo && (
          <Group className="ui-row-item__group--good-price">
            <ItemInfoHighlight {...itemHighlightInfo} />
          </Group>
        )}
        {item.pills_builder && (
          <div className="ui-row-item__mot-pills--builder">
            <PillsBuilder pills={item.pills_builder} />
          </div>
        )}
        {renderSpotlight(item, 'bottom')}
        {item.descriptions && (
          <ItemAttributes
            attributes={item.descriptions}
            className="ui-row-item__attributes ui-row-item__attributes--mot"
          />
        )}
        {item.distance?.text && (
          <ItemDistance distance={item.distance.text} />
        )}
        {item.official_store && (
          <ItemOfficialStore
            label={
              item.verboseLabels
                ? item.official_store.verbose_text
                : item.official_store.text
            }
            icon={item.official_store.has_official_store_icon}
          />
        )}
      </Group>
    </div>
  );
}

function renderResContent(item, layout, deviceType, country, currencies) {
  return (
    <div className={`${namespace}__content`}>
      {renderMediaTagGroup(item)}
      <Group className="ui-row-item__group--title">
        <ItemTitle title={item.title} compats={item.title_compats} />
      </Group>
      {renderSpotlight(item)}
      <Group>
        {renderPrice(item, deviceType, country, currencies)}
        {renderSpotlight(item, 'bottom')}
        {item.descriptions && (
          <ItemAttributes
            attributes={item.descriptions}
            className="ui-row-item__attributes ui-row-item__attributes--res"
          />
        )}
      </Group>
      <Group>
        <span className="ui-row-item__location">{item.location}</span>
      </Group>
    </div>
  );
}

const renderShopsAbsolutePills = (item) => (
  <div className="ui-row-item__shops-pills--absolute">
    {renderPills(item)}
  </div>
);

function verticalContentRenderer(
  item,
  layout,
  deviceType,
  country,
  currencies,
  preload,
  lowEnd,
  isShops,
) {
  if (isShops) {
    return renderShopsContent(item, layout, deviceType, country, currencies);
  }

  const { vertical } = item;
  switch (vertical) {
    case VERTICAL_TYPE_SRV:
      return renderSrvContent(item, layout, deviceType, country, currencies);
    case VERTICAL_TYPE_MOT:
      return renderMotContent(item, layout, deviceType, country, currencies);
    case VERTICAL_TYPE_RES:
      return renderResContent(item, layout, deviceType, country, currencies);
    case VERTICAL_TYPE_CORE:
    default:
      return renderCoreContent(
        item,
        layout,
        deviceType,
        country,
        currencies,
        preload,
        lowEnd,
      );
  }
}

const LAZY_ON = 'on';
const LAZY_OFF = 'off';

const DefaultItemRenderer = ({
  item,
  layout,
  i,
  onClickBookmark,
  bookmarked,
  options = {},
  children,
  deviceType,
  country,
  currencies,
  showBookmarks,
  renderAddToCartComponent,
  renderFreeShippingThMessage,
  isShops,
  bookmarkLabelText,
}) => {
  const SHOW_USER_BOOKMARKS = showBookmarks;
  const { title, permalink, pictures, vertical } = item;
  const isCpg = Boolean(item.cpg);
  const itemVertical = vertical ? vertical.toLowerCase() : null;
  const preload = i < configPreload[`${layout}`];
  const wrapperClassname = classnames(`${namespace}__item-wrapper`, {
    [`${namespace}--${itemVertical}__item-wrapper`]: itemVertical,
  });
  const imageContainerClassname = classnames(
    `${namespace}__item-image-container`,
    { [`${namespace}--${itemVertical}__item-image-container`]: itemVertical },
  );
  const figureClassname = classnames(`${namespace}__image-container`, {
    [`${namespace}__image-container--${itemVertical}`]: itemVertical,
  });

  return (
    <Card animated={false}>
      <CardContent
        className={classnames(namespace, {
          [`${namespace}--${itemVertical}`]: itemVertical,
        })}
      >
        <div className={wrapperClassname}>
          {isShops && renderShopsAbsolutePills(item)}
          <div className={imageContainerClassname}>
            <figure
              className={classnames(figureClassname, {
                  [`${namespace}__crop-image`]: get(
                    pictures,
                    `${layout}.tags.should_crop`,
                  ),
                })}
            >
              <div className={`${namespace}__overlay-image`} />
              <Image
                alt={item.title}
                className={`${namespace}__image`}
                src={get(pictures, `${layout}.retina`)}
                width={get(pictures, `${layout}.tags.width`)}
                height={get(pictures, `${layout}.tags.heigth`)}
                lazyload={preload ? LAZY_OFF : LAZY_ON}
                preload={preload}
                lowEnd={options.lowEnd === true}
                aria-hidden
              />
            </figure>
          </div>
          {SHOW_USER_BOOKMARKS && !item.pds_info && (
            <ItemBookmark
              onClickBookmark={onClickBookmark}
              itemId={item.id}
              bookmarked={bookmarked}
              bookmarkLabelText={bookmarkLabelText}
            />
          )}
          <div className="ui-row-item-info">
            <Wrapper
              className={`${namespace}__cpg-wrapper`}
              isCpg={isCpg}
              item={item}
              layout={layout}
              renderAddToCartComponent={renderAddToCartComponent}
              renderFreeShippingThMessage={renderFreeShippingThMessage}
            >
              <Link
                className={`${namespace}__link`}
                href={permalink}
                title={title}
                isAd={item.is_ad}
              >
                {verticalContentRenderer(
                  item,
                  layout,
                  deviceType,
                  country,
                  currencies,
                  preload,
                  options.lowEnd,
                  isShops,
                )}
              </Link>
            </Wrapper>
            {item.is_ad && (
              <span
                className={`ui-row-item__ad-label ${
                  item.ad_version === 'v2' ? 'ui-row-item__ad-label--blue' : ''
                }`}
              >
                {item.ad_label}
              </span>
            )}
            {item.actions && (
              <div className="ui-row-main__actions">
                {item.actions.map((action) => (
                  <Action key={action.id} {...action} />
                ))}
              </div>
            )}
          </div>
        </div>
        {children}
      </CardContent>
    </Card>
  );
};

export { DefaultItemRenderer };
