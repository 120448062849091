import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--star ui-search-icon--star-half';
const ICON_ID = 'poly_star_half';

const IconStarHalf = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 10 10"
    color={props.color ?? '#3483fa'}
    iconId={ICON_ID}
  />
);

IconStarHalf.propTypes = {
  className: string,
  color: string,
  style: string,
};

export default IconStarHalf;
