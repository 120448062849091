import React from 'react';
import { shape, arrayOf, string, oneOf, node, oneOfType, number, bool } from 'prop-types';

import ComposedLabelAlternatives from './composed-label-alternatives';
import IconChevronRight from '../../../../../../components-v2/icons/sources/icon-chevron-right'

const namespace = 'ui-row-winner-alternatives';

const renderLabel = ({ componentId, componentIndex, country, itemId, alternative }) => {
  switch (componentId) {
    case 'TEXT':
      return (
        <ComposedLabelAlternatives
          country={country}
          itemId={itemId}
          alternative={alternative}
          componentIndex={componentIndex}
        />
        );

    default:
      return null;
  }
};


const WithBorderType = ({ type, children }) => {
  if (type === 'chevron') {
    return <div className={`${namespace}--${type}`}> <div>{children}</div>  <IconChevronRight /> </div>;
  }

  return <div className={`${namespace}--${type}`}> {children}</div>;
};

WithBorderType.propTypes = {
  children: node.isRequired,
  type: string,
};


const WinnerAlternatives = ({ alternatives, country, itemId, alternativeProp }) => {
  const onClickAlternative = (event, permalink) => {
    event.preventDefault();
    window.location.href = permalink;
  };

  return (
    <ul>
      {alternatives && alternatives.map((alternative) => (
        <li key={alternative.id}>
          <button
            type="button"
            data-testid={`${namespace}__link`}
            className={`${namespace}__link`}
            onClick={(ev) => alternative.permalink && onClickAlternative(ev, alternative.permalink)}
          >
            <WithBorderType type={alternative.type}>
              {alternative.components.map((component, i) => (
                <React.Fragment key={`${component.id}`}>
                  {renderLabel({ componentId: component.id, componentIndex: i, country, itemId, alternative: { id: alternative.id, prop: alternativeProp } })}
                </React.Fragment>))}
            </WithBorderType>
          </button>
        </li>
      ))}
    </ul>
  );
};


WinnerAlternatives.propTypes = {
  alternatives: arrayOf(shape({
      id: string,
      type: oneOf(['sandwich', 'chevron', 'borderless', 'border-blue', 'border-grey', 'separator-top']).isRequired,
      permalink: string,
      components: arrayOf(shape({
        id: string,
        label: shape({
          text: string.isRequired,
          color: string,
          font_weight: string,
          font_size: oneOfType([string, number]),
          margin: shape({
            bottom: number,
            top: number,
          }),
          values: arrayOf(shape({
            type: oneOf(['price', 'text', 'icon']),
            key: string,
            amount: number,
            previous: bool,
            font_size: oneOfType([string, number]),
            id: string,
            font_weight: string,
          })),
        }),
      })),
    })),
  country: shape({}).isRequired,
};

export default WinnerAlternatives;
