import React from 'react';
import { string, bool } from 'prop-types';

import classNames from 'classnames';

import IconBookmark from '../../../../../components-v2/icons/sources/bookmark';

const namespace = 'ui-row-bookmark';

const Bookmark = ({ className, bookmarked, onClickBookmark, itemId, bookmarkLabelText }) => {
  const handleBookmark = (e) => {
    e.preventDefault();
    onClickBookmark(e, itemId);
  };

  const iconFilledClassNames = classNames(`${namespace}__icon-bookmark-fill`, {
    [`${namespace}__icon-bookmark-fill--active`]: bookmarked,
  });

  return (
    <form
      className={`${classNames(namespace, className)}`}
      method="POST"
      onSubmit={handleBookmark}
    >
      <button type="submit" className={`${namespace}__btn`} aria-label={bookmarkLabelText}>
        <IconBookmark className={`${namespace}__icon-bookmark`} />
        <IconBookmark className={iconFilledClassNames} />
      </button>
    </form>
  );
};

Bookmark.propTypes = {
  bookmarked: bool,
  bookmarkLabelText: string,
  className: string,
  itemId: string.isRequired,
};
Bookmark.defaultProps = {
  bookmarked: false,
  className: '',
};

export default Bookmark;
