import React from 'react';

import classnames from 'classnames';
import { string, number } from 'prop-types';
import Price from '../price';

const namespace = 'ui-row-item__pds-best-price';

const PdsBestPrice = ({
  className,
  amount,
  currency_id,
  deviceType,
  country,
  currencies,
}) => (
  <Price
    amount={amount}
    currency_id={currency_id}
    size="x-tiny"
    className={classnames(namespace, className, 'ui-row-color--black')}
    showDecimals={false}
    deviceType={deviceType}
    currencies={currencies}
    country={country}
  />
);

PdsBestPrice.propTypes = {
  className: string,
  amount: number.isRequired,
  currency_id: string.isRequired,
};

PdsBestPrice.defaultProps = {
  className: null,
};

export default PdsBestPrice;
