import React from 'react';
import { string, shape } from 'prop-types';
import classnames from 'classnames';
import getIcon from '../../../../../../components-v2/icons';

const namespace = 'ui-row-vertical-highlight';

const VerticalHighlight = ({ className, id, icon }) => (
  <div
    className={classnames(namespace, className, `${namespace}--${id}`)}
  >
    {getIcon({ iconId: icon.id,  className: `${namespace}__icon`})}
  </div>
);

VerticalHighlight.propTypes = {
  className: string,
  id: string.isRequired,
  icon: shape({
    id: string.isRequired,
    color: string,
  }).isRequired,
};

export default VerticalHighlight;
