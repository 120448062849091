import React from 'react';
import { string, number, bool, shape } from 'prop-types';

import MoneyAmount from '@andes/money-amount';
import classNames from 'classnames';

import { getFormattedIntFraction, getFormattedDecimalFraction } from '../../../../../../utils/format-price';

const namespace = 'ui-row-price__part';


const getSize = (size) => {
  let value = 20;

  switch (size) {
    case 'large':
      value = 36;

      break;
    case 'x-tiny':
      value = 12;

      break;
    case 'tiny':
    case 'small':
      value = 16;

      break;
    case 'medium':
      value = 20;

      break;

    default:
      break;
  }

  return value;
};

const PricePart = ({
  value,
  thousandSeparator,
  currencySymbol,
  className,
  showDecimals,
  strikeThrough,
  size,
  bulksale,
}) => {
  const intFraction = getFormattedIntFraction(value, thousandSeparator);
  const decimalFraction = getFormattedDecimalFraction(value);
  const sizeNum = getSize(size);

  return (
    <MoneyAmount
      className={classNames(namespace, `ui-row-price--size-${size}`, className)}
      value={{
        fraction: intFraction,
        cents: showDecimals ? decimalFraction : null,
      }}
      suffix={bulksale ? bulksale.unit_measure : ''}
      symbol={currencySymbol}
      previous={strikeThrough}
      size={sizeNum}
      centsType="superscript"
      compact
    />
  );
};

PricePart.propTypes = {
  bulksale: shape({}),
  className: string,
  currencySymbol: string.isRequired,
  showDecimals: bool,
  size: string,
  strikeThrough: bool,
  thousandSeparator: string.isRequired,
  value: number.isRequired,
};

PricePart.defaultProps = {
  bulksale: null,
  className: null,
  size: 'tiny',
  showDecimals: true,
  strikeThrough: false,
};

export default PricePart;
