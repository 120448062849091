import pick from 'lodash/pick';

const SUPPORTED_STYLE_PROPS = [
  'color',
  'background',
];

const getStyleProps = props => pick(props, SUPPORTED_STYLE_PROPS);

export {
  getStyleProps,
};
