import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--cockade';
const ICON_ID = 'poly_cockade';

const IconCockade = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 14 14"
    ariaHidden
    iconId={ICON_ID}
    width="14"
    height="14"
  />
);

IconCockade.propTypes = {
  className: string,
  style: string,
};

export default IconCockade;
