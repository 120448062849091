import React from 'react';
import { string } from 'prop-types';

import IconCoupon from '../../../../../components-v2/icons/sources/icon-coupons';
import Promotions from '../card/promotions';

const Pill = ({ element, deviceType }) => {
  const { promotion_type, label, currency, background, color } = element;

  let windowSize = 170;

  if (typeof window !== 'undefined') {
    windowSize = window.innerWidth > 279 ? 170 : 150;
  }

  const getIconComponent = {
    coupon: IconCoupon,
  };

  const promotionIcon = getIconComponent[promotion_type] || null;

  return (
    <Promotions
      label={label}
      currency={currency}
      deviceType={deviceType}
      background={background}
      color={color}
      contentWidth={windowSize}
      promotionIcon={promotionIcon}
    />
  );
};

Pill.propTypes = {
  label: string,
  promotion_type: string,
};

Pill.defaultProps = {
  label: '',
  promotion_type: '',
};

export default Pill;
