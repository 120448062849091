import React from 'react';
import { func, string } from 'prop-types';

const goToAction = (url) => {
  window.location = url;
};

const Action = ({ label, onClick, target }) => (
  <button
    type='button'
    className="ui-row__action"
    data-testid="action-label"
    onClick={
      onClick
      || ((e) => {
        e.preventDefault();
        goToAction(target);
      })
    }
  >
    {label}
  </button>
);

Action.propTypes = {
  label: string.isRequired,
  target: string,
  onClick: func,
};

Action.defaultProps = {
  target: null,
  onClick: null,
};

export default Action;
