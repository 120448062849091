import React, { useState } from 'react';
import { string, shape, number } from 'prop-types';

import classnames from 'classnames';

import { DefaultItemRenderer } from '../stack-rows/ui-row';
import { useStaticProps } from '../../context/static-props';
import APIService from '../../../../services/bookmarks';
import { useUser } from '../../context/user';
import { useSearch } from '../../../hooks/context';
import { renderAddToCartComponent } from '../renderer/desktop-card-content/common';
import { renderFreeShippingThMessage } from '../renderer/desktop-card-content/core';
import { toggleBookmark } from '../../context/search';

const redirect = (loginUrl) => {
  window.location.href = loginUrl;
};

const OneColumnStackItemLayout = ({ item, layout, index }) => {
  const { lowEnd, country, deviceType } = useStaticProps();
  const { loggedIn, loginUrl } = useUser();
  const { bookmark, shops } = useSearch();
  const [bookmarkState, setBookmarkState] = useState(item.bookmarked);
  const { currencies } = country;
  const isShops = Boolean(shops);

  let isFetching = false;

  const handleBookmark = (e, itemId) => {
    e.preventDefault();

    if (!isFetching) {
      const newBookmarkState = toggleBookmark(itemId);

      setBookmarkState(newBookmarkState);

      if (!loggedIn) {
        redirect(loginUrl);

        return;
      }

      isFetching = true;
      APIService.toggleBookmarkService(itemId, newBookmarkState)
        .catch((ex) => {
          if (ex.response && ex.response.status === 403) {
            redirect(loginUrl);

            return;
          }

          setBookmarkState(!newBookmarkState);
          toggleBookmark(itemId);
        })
        .finally(() => {
          isFetching = false;
        });
    }
  };

  const namespace = 'ui-search-row';
  const defaultItemRendererWrapper = classnames(namespace, {
    'shops__container-row': isShops,
  });

  return (
    <div className={defaultItemRendererWrapper}>
      <DefaultItemRenderer
        item={item}
        layout={layout}
        i={index}
        options={{ lowEnd }}
        onClickBookmark={handleBookmark}
        bookmarked={bookmarkState}
        deviceType={deviceType}
        country={country}
        currencies={currencies}
        showBookmarks={bookmark?.show_user_bookmarks}
        bookmarkLabelText={bookmark?.label?.text}
        renderAddToCartComponent={renderAddToCartComponent}
        renderFreeShippingThMessage={renderFreeShippingThMessage}
        isShops={isShops}
      />
    </div>
  );
};

// @TODO improve results shape definition
OneColumnStackItemLayout.propTypes = {
  index: number.isRequired,
  item: shape({
    title: string,
  }).isRequired,
  layout: string.isRequired,
};

export default OneColumnStackItemLayout;
