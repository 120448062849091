import React from 'react';
import { number, string, shape } from 'prop-types';
import classnames from 'classnames';
import Price from '../price';
import { getStyleProps } from '../../utils/style-props';

const namespace = 'ui-row-price-details';

const PRICE_FORMAT_SPECIFIER = '{price}';

const PriceDetails = ({
  className,
  priceDetails,
  ...props
}) => {
  const { label: { text, color } = {}, price: { amount, currency_id } = {} } = priceDetails;
  const priceIndex = text.indexOf(PRICE_FORMAT_SPECIFIER);
  const priceDetailsClassName = classnames(className, namespace);
  const showDecimals = amount && parseInt(amount.toFixed(2).split('.')[0], 10) < 1000;
  if (priceIndex >= 0) {
    return (
      <span className={priceDetailsClassName} style={getStyleProps({ color })}>
        {text.substring(0, priceIndex)}
        <Price amount={amount} currency_id={currency_id} size="tiny" showDecimals={showDecimals} {...props} />
        {text.substring(priceIndex + PRICE_FORMAT_SPECIFIER.length).trim()}
      </span>
    );
  }

  return (<span className={priceDetailsClassName}>{text}</span>);
};

PriceDetails.propTypes = {
  className: string,
  priceDetails: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }),
    price: shape({
      amount: number,
      currency_id: string,
    }),
  }).isRequired,
};

PriceDetails.defaultProps = {
  className: null,
};

export default PriceDetails;
