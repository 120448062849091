import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import IconFull from '../../../../../../components-v2/icons/sources/full';

const namespace = 'ui-row-item__fulfillment';

const Full = ({
  className,
}) => (
  <span className={classnames(namespace, className)}>
    <IconFull />
  </span>
);

Full.propTypes = {
  className: string,
};

Full.defaultProps = {
  className: null,
};

export default Full;
