import React from 'react';
import { bool, node, shape, string } from 'prop-types';

const Wrapper = ({ children, className, isCpg, item, layout, renderAddToCartComponent, renderFreeShippingThMessage }) => {
  if (isCpg) {
    return (
      <div className={className}>
        {children}
        {renderFreeShippingThMessage(item, layout)}
        {renderAddToCartComponent(item)}
      </div>
    );
  }
  return children;
};

Wrapper.propTypes = {
  children: node.isRequired,
  className: string,
  isCpg: bool,
  item: shape(),
  layout: string,
};

export default Wrapper;
