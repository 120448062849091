import React, { useMemo } from 'react';
import { arrayOf, string, shape, func } from 'prop-types';

import classnames from 'classnames';

import WrappedContext from '../../wrapped-context';
import OneColumnItem from './one-column-stack-item';
import OneColumnItemPolycard from './one-column-item-polycard';
import { useIsFade } from '../../context/layout-fade';
import { useStaticProps } from '../../context/static-props';
import { useCurrentItemSuggestion, useOpenSuggestions } from '../../context/suggest-cpg';
import { renderOneColumnStackComponents } from '../renderer/utils/render-results';

const OneColumnStackLayout = ({ results, polycard_context, layout, itemComponent }) => {
  const isFade = useIsFade();
  const { vertical, lowEnd, deviceType, country } = useStaticProps();
  const options = useMemo(() => ({ lowEnd, verboseLabels: true }), [lowEnd]);
  const currentItemSuggestion = useCurrentItemSuggestion();
  const openSuggestions = useOpenSuggestions();
  const isCPG = vertical === 'CPG';
  const namespace = isCPG ? 'ui-search-layout' : 'ui-search-row';
  const type = 'list';

  let LayoutItem = null;
  let customIndex = 0;

  if (isCPG && !polycard_context) {
    LayoutItem = itemComponent;
  } else if (polycard_context) {
    LayoutItem = OneColumnItemPolycard;
  } else {
    LayoutItem = OneColumnItem;
  }

  return (
    <WrappedContext
      contextValue={polycard_context}
      layout={layout}
      type={type}
      deviceType={deviceType}
      countryConfig={country}
    >
      <ol
        className={classnames(namespace, { [`${namespace}--${layout}`]: layout }, { [`${namespace}--fade`]: isFade })}
      >
        {results.map((item) => {
          if (!item.id.includes('INTERVENTION')) {
            customIndex += 1;
          }

          return renderOneColumnStackComponents(item, customIndex, {
            namespace,
            LayoutItem,
            currentItemSuggestion,
            results,
            layout,
            options,
            openSuggestions,
          });
        })}
      </ol>
    </WrappedContext>
  );
};

// @TODO improve results shape definition
OneColumnStackLayout.propTypes = {
  itemComponent: func.isRequired,
  layout: string.isRequired,
  polycard_context: shape({}),
  results: arrayOf(
    shape({
      title: string,
    }),
  ),
};

OneColumnStackLayout.defaultProps = {
  results: [],
  polycard_context: null,
};

export default OneColumnStackLayout;
