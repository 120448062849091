import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--star ui-search-icon--star-full';
const ICON_ID = 'poly_star';

const IconStarEmpty = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 11 10"
    iconId={ICON_ID}
    color="none"
  />
);

IconStarEmpty.propTypes = {
  className: string,
  style: string,
};

export default IconStarEmpty;
