/* eslint-disable max-depth */
import React from 'react';
import omit from 'lodash/omit';
import get from 'lodash/get';
import pick from 'lodash/pick';
import StyledLabel from '../../styled-label/styled-label';
import ComposedLabelValue from '../../composed-label/composed-label-value';
import { RenderPrice } from './render-price';
import {
  setItemAlternativeComponentValueSize,
  setItemAlternativeComponentSize,
  useAlternativeComponentLabel,
} from '../../../../../context/search'

const namespace = 'ui-row-composed-label-alternatives';

const GENERIC_KEY_PREFIXES = {
  TOP_COLUMN: 'TOP',
  LEFT_COLUMN: 'LEFT',
  RIGHT_COLUMN: 'RIGHT',
  MODAL_COMPONENTS: 'MODAL_COMPONENTS',
};
const mapWithKey = (itemId, components, keyPrefix) => components.map(
  (component) => component && React.cloneElement(component, { key: `${keyPrefix}-${itemId}` }),
);

const PLACEHOLDER_FORMAT_REGEX = /\{\w+\}/gi;
const PLACEHOLDER_DELIMITERS_REGEX = /{|}/;

const getStyleProps = props => {
  const styleMap = {
    marginBottom: get(props, 'margin.bottom'),
    borderRadius: get(props, 'border_radius'),
  };
  const ALLOWED_STYLES = pick({ ...props, ...styleMap },
    ['borderRadius', 'marginBottom', 'background', 'padding', 'color']);
  return ALLOWED_STYLES;
};

const renderNormalText = (
  text,
  props,
  {
    itemId,
    alternative,
    componentIndex,
  }) => {
    if (props.font_size && props.size !== props.font_size) {
      try {
        setItemAlternativeComponentSize({ itemId, alternative, componentIndex });
      } catch (e) {
        // ignore
      }
    }

    return (
      <StyledLabel
        className={`${namespace}--normal-text`}
        text={text}
        {...omit(props, ['padding'])}
      />);
};


const ComposedLabelAlternatives = ({ itemId, alternative, componentIndex, country }) => {
  const { text = '', values = [], className, ...alternativeProps } = useAlternativeComponentLabel({ itemId, alternative, componentIndex });
  const props = {
    ...alternativeProps,
    country,
  };
  const componentsToRender = [];

  let currentIndex = 0;
  let match = PLACEHOLDER_FORMAT_REGEX.exec(text);

  while (match !== null) {
    const matchIndex = match.index;
    const placeHolder = match[0];
    const valueKey = placeHolder.split(PLACEHOLDER_DELIMITERS_REGEX)[1];
    const normalTextToRender = matchIndex > 0 && text.substring(currentIndex, matchIndex);

    currentIndex = matchIndex + placeHolder.length;

    if (normalTextToRender) {
      componentsToRender.push(renderNormalText(
        normalTextToRender,
        props,
        {
          itemId,
          alternative,
          componentIndex,
        },
      ));
    }

    const valueProps = values && values.find((value) => value.key === valueKey);
    if (valueProps) {
      switch (valueProps.type) {
        case 'price':
          componentsToRender
          .push(<RenderPrice className={`${namespace}__price`} country={props.country} {...valueProps} />);
          break;
        case 'icon':
        case 'text':
        default: {
          if (valueProps.font_size && valueProps.size !== valueProps.font_size) {
            try {
              setItemAlternativeComponentValueSize({ itemId, alternative, componentIndex, valueKey });
            } catch (e) {
              // ignore
            }
          }

          componentsToRender.push(
            <ComposedLabelValue
              className={`${namespace}__value`}
              as="pre"
              {...props}
              {...valueProps}
            />,
          );
          break;
        }
      }
    }
    match = PLACEHOLDER_FORMAT_REGEX.exec(text);
  }

  if (currentIndex < text.length) {
    const component = renderNormalText(
      text.substring(currentIndex),
      props,
      {
        itemId,
        alternative,
        componentIndex,
      },
    );
    componentsToRender.push(component);
  }

  return (
    <div className={`${namespace}__container`} style={getStyleProps(props)}>
      <div className={`${namespace}__items`}>
        {mapWithKey(itemId, componentsToRender, GENERIC_KEY_PREFIXES.MODAL_COMPONENTS)}
      </div>
    </div>
  );
};

export default ComposedLabelAlternatives;
