import React from 'react';
import { string, shape } from 'prop-types';
import StyledLabel from '../../styled-label/styled-label';

const namespace = 'ui-search-item__promise-message';

const PromiseMessage = ({ manufacturingTime }) => {
  const { font_size: size, ...rest } = manufacturingTime;
  return (
    <StyledLabel className={namespace} size={size} {...rest} />
  );
};

PromiseMessage.propTypes = {
  manufacturingTime: shape({
    id: string,
    text: string,
    color: string,
    font_size: string,
    font_weight: string,
  }).isRequired,
};

export default PromiseMessage;
