import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--close';

const ICON_ID = 'poly_close';

const IconClose = (props) => (
  <BuildIcon {...props} className={classnames(namespace, props.className)} viewBox="0 0 1024 1024" iconId={ICON_ID} />
);

IconClose.propTypes = {
  className: string,
};

export default IconClose;
