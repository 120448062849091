import React from 'react';
import { number, string, shape } from 'prop-types';

import cx from 'classnames';
import MoneyAmount from '@andes/money-amount';

import IconMeliCoin from '../../../../../../components-v2/icons/sources/icon-melicoin';


const namespace = 'ui-row-melicoin';

const findClassToFill = (start, end) => {
  let classToFill = '';

  if (start !== '' && end !== '') {
    classToFill = `${namespace}__spaceMiddle`;
  } else if (start !== '') {
    classToFill = `${namespace}__spaceStart`;
  } else if (end !== '') {
    classToFill = `${namespace}__spaceFinal`;
  }

  return classToFill;
};

const fitPriceInText = (text, currency) => {
  let startWording = '';
  let finalWording = '';
  let priceSetted = false;

  text.split(' ').forEach(word => {
    if (word.includes('{')) {
      startWording = startWording !== '' ? startWording.concat(' ') : startWording;
      priceSetted = true;

      return;
    }

    if (!priceSetted) {
      startWording = startWording === '' ? startWording.concat('', word) : startWording.concat(' ', word);
    } else {finalWording = finalWording.concat(' ', word);}
  });

  return (
    <>
      {startWording}
      {currency && priceSetted
       && (
       <MoneyAmount
         className={findClassToFill(startWording, finalWording)}
         value={{
           cents: currency.cents,
           fraction: currency.fraction,
         }}
         currencyId={currency.currencyId}
         symbol={currency.symbol}
         centsType={currency.centsType}
         weight="semibold"
         size={12}
       />
       )}
      {finalWording}
    </>
  );
};

const MeliCoin = ({ label, currency, class_name }) => (
  <div className={cx(namespace, class_name)}>
    <div className={`${namespace}__label`}>
      { fitPriceInText(label, currency) }
      <IconMeliCoin />
    </div>
  </div>
);

MeliCoin.propTypes = {
  class_name: number.isRequired,
  currency: shape.isRequired,
  label: string.isRequired,
};

export default MeliCoin;
