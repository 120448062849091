import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import classnames from 'classnames';
import Group from '../group/group';
import Separator from '../group/separator';
import ItemFull from '../fulfillment/fulfillment';
import ItemPromise from '../promise/promise.mobile';
import ItemHighlight from '../highlight/highlight-label';

const namespace = 'ui-row-item__shipping';

const ShippingGroup = ({ shipping, tags, contentWidth }) => {
  const { label, highlight, promise } = shipping;
  const hasFulfillment = tags && tags.indexOf('fulfillment') > -1;
  const shouldShowPromise = promise && promise.id !== 'other';
  const showFirstRow = label || hasFulfillment || shouldShowPromise;

  if (!showFirstRow && !highlight) return null;

  return (
    <Group noSeparator>
      {showFirstRow && (
        <div className="ui-row-item__group__element--shipping">
          {label && !shouldShowPromise && (
            <p className={classnames(namespace, `${namespace}--free`)}>
              {label.text}
            </p>
          )}
          {hasFulfillment && !shouldShowPromise && <ItemFull />}
          {shouldShowPromise && (
            <ItemPromise
              {...promise}
              contentWidth={contentWidth}
              hasFulfillment={hasFulfillment}
            />
          )}
        </div>
      )}
      {showFirstRow && highlight && <Separator />}
      {highlight && (
        <div>
          <ItemHighlight {...highlight} className={`${namespace}__highlight`} />
        </div>
      )}
    </Group>
  );
};

ShippingGroup.propTypes = {
  className: string,
  shipping: shape({
    highlight: shape({}),
    label: shape({
      text: string.isRequired,
    }),
    promise: shape({}),
  }),
  tags: arrayOf(string).isRequired,
  contentWidth: number.isRequired,
};

ShippingGroup.defaultProps = {
  className: null,
  shipping: {},
};

export default ShippingGroup;
