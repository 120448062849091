import { shape, arrayOf, string } from 'prop-types';

const seoTopKeywordsInterventionPropTypes = {
  keywords: arrayOf(
    shape({
      label: string.isRequired,
      url: string.isRequired,
    }),
  ).isRequired,
  title: string.isRequired,
};

export { seoTopKeywordsInterventionPropTypes };
