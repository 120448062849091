import React from 'react';
import { number, bool, shape, string } from 'prop-types';

import classNames from 'classnames';
import MoneyAmount from '@andes/money-amount';

import { getFormattedIntFraction, getFormattedDecimalFraction } from '../../../../../../utils/format-price';


const RenderPrice = ({ amount, className, ...props }) => {
  const { country, font_size: size, previous, color } = props;
  const { currency_id, thousands_separator: thousandSeparator } = country;
  const newAmount = getFormattedIntFraction(amount, thousandSeparator);
  const amountDecimal = getFormattedDecimalFraction(amount);

  return (
    <div
      style={{ color }}
      className={
        classNames(className,
            { [`ui-row-weight--${props.font_weight}`]: !!props.font_weight })
      }
    >
      <MoneyAmount
        centsType="superscript"
        amount={{
          cents: amountDecimal,
          currencyId: currency_id,
          fraction: newAmount,
        }}
        currentValue={{
          centsType: 'superscript',
        }}
        size={size}
        previous={previous}
      />
    </div>
  );
};

RenderPrice.defaultProps = {
  previous: false,
};
RenderPrice.propTypes = {
  amount: number.isRequired,
  className: string,
  color: string,
  country: shape({
    currency_id: string.isRequired,
    thousands_separator: string.isRequired,
  }),
  font_size: number,
  font_weight: string,
  previous: bool,
};

export { RenderPrice };
