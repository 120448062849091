import React, { useState } from 'react';
import Image from 'nordic/image';
import get from 'lodash/get';
import { shape, bool, arrayOf } from 'prop-types';
import classNames from 'classnames';

const namespace = 'ui-row-variations-pickers';
const LAZY_ON = 'on';
const LAZY_OFF = 'off';
const getPermalink = (option) => option?.item?.permalink;

const VariationsPicker = ({ variations_pickers, preload, lowEnd }) => {
  const { options } = variations_pickers;
  const selectedItem = options.find((item) => item.is_selected);
  const [pickedVariation, setPickedVariation] = useState(
    selectedItem ? selectedItem.id : options[0].id,
  );
  const thumbnails = get(variations_pickers, 'thumbnails_to_show.stack');
  const getClassName = (v) => `${namespace}__${
    pickedVariation === v ? 'variation-selected' : 'variation'
  }`;
  const variationsToShow = thumbnails
    ? options.filter((v, i) => i < thumbnails.max_thumbnails - 1)
    : options;

  return (
    <div className={namespace}>
      {variationsToShow.map((variation) => (
        <object>
          <a
            aria-label={variation.name}
            href={getPermalink(variation)}
            role="button"
            onClick={() => {
              setPickedVariation(variation.id);
            }}
            key={variation.id}
            className={`${namespace}__link`}
          >
            <Image
              src={get(variation, 'thumbnail.retina')}
              className={getClassName(variation.id)}
              alt={variation.name}
              lazyload={preload ? LAZY_OFF : LAZY_ON}
              preload={preload}
              lowEnd={lowEnd}
            />
          </a>
        </object>
      ))}
      {thumbnails && thumbnails.show_more_text && (
        <object>
          <a
            className={classNames(
              getClassName('label-more-options'),
              `${namespace}__link`,
            )}
            aria-label={thumbnails.show_more_text}
            href={getPermalink(selectedItem)}
            role="button"
            onClick={() => setPickedVariation('label-more-options')}
          >
            <span>{thumbnails.show_more_text}</span>
          </a>
        </object>
      )}
    </div>
  );
};

VariationsPicker.propTypes = {
  variations_pickers: shape({
    thumbnails_to_show: shape({
      stack: shape({}),
      grid: shape({}),
      default: shape({}),
    }),
    options: arrayOf(shape({})).isRequired,
  }).isRequired,
  preload: bool,
  lowEnd: bool,
};

VariationsPicker.defaultProps = {
  variations_pickers: {},
  preload: false,
  lowEnd: false,
};

export default VariationsPicker;
