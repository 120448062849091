import { shape, string } from 'prop-types';

const seoKeywordSummaryInterventionPropTypes = {
  intervention: shape({
    keyword: string.isRequired,
    summary: string.isRequired,
    alt_image: string.isRequired,
    image_url: string.isRequired,
    expansion_text: string.isRequired,
    contraction_text: string.isRequired,
  }),
};

export { seoKeywordSummaryInterventionPropTypes };
