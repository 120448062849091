const namespace = 'ui-search-seo-keyword-summary-intervention';
const expandablePropsSize = 'small';
const expandablePropsColor = 'link';
const expandablePropsDefaultHeightDesktop = 130;
const expandablePropsDefaultHeightMobile = 60;
const polycardDesktop = 'poly-card poly-card--list';
const polycardMobile = 'poly-card poly-card--list poly-card--mobile poly-card--CORE';
const preloadImage = true;

export {
  namespace,
  expandablePropsSize,
  expandablePropsColor,
  expandablePropsDefaultHeightDesktop,
  expandablePropsDefaultHeightMobile,
  polycardDesktop,
  polycardMobile,
  preloadImage,
};
