import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import classnames from 'classnames';
import HighlightLabel from '../highlight/highlight-label';

const namespace = 'ui-row-value-proposition';

const ValueProposition = ({ className, id, pills, styled_icon: icon }) => (
  <div
    className={classnames(namespace, className, { [`${namespace}--${id}`]: !!id })}
  >
    <div className={`${namespace}__pills`}>
      {pills.map((pill) => (
        <HighlightLabel
          key={`${pill.type}-${id}`}
          className={`${namespace}__pill`}
          icon={icon}
          {...pill}
        />))}
    </div>
  </div>
);

ValueProposition.propTypes = {
  className: string,
  id: string,
  pills: arrayOf(
    shape({
      type: string,
      icon: shape(),
      label: shape(),
    }),
  ).isRequired,
  styled_icon: shape({
    id: string,
    color: string,
  }),
};

ValueProposition.defaultProps = {
  id: null,
  className: null,
  styled_icon: null,
};

export default ValueProposition;
