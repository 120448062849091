import React from 'react';
import { string, shape, number } from 'prop-types';

import classnames from 'classnames';

import { getStyleProps } from '../../utils/style-props';
import StyledLabel from '../../styled-label/styled-label';
import getIcon from '../../../../../../components-v2/icons';

const namespace = 'ui-row-item__highlight-label';

const HighlightLabel = ({ className, type, highlightsPosition, icon, label, ...props }) => {
  const hasType = !!type;

  return (
    <div
      className={classnames(
        namespace,
        className,
        { [`${namespace}--${type}`]: hasType },
        { [`${namespace}--no-background`]: !props.background },
      )}
      style={getStyleProps(props)}
    >
      <div className={`${namespace}__container`}>
        {icon && highlightsPosition === 'left' && getIcon({iconId: icon.id})}
        {label && (
          <StyledLabel
            className={classnames(`${namespace}__text`, { [`${namespace}__text--no-padding`]: hasType })}
            {...label}
          />)}
        {icon && highlightsPosition === 'right' && getIcon({iconId: icon.id})}
      </div>
    </div>
  );
};

HighlightLabel.propTypes = {
  background: string,
  className: string,
  highlightsPosition: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  label: shape({
    text: string.isRequired,
    color: string,
    background: string,
    size: number,
    weight: number,
    style: string,
  }),
  type: string,
};

HighlightLabel.defaultProps = {
  className: null,
  type: null,
  icon: null,
  label: null,
  background: null,
  highlightsPosition: 'left',
};

export default HighlightLabel;
