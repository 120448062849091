import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import classnames from 'classnames';
import ValueProposition from './value-proposition';

const namespace = 'ui-row-value-propositions-list';

const ValuePropositions = ({ className, propositions }) => (
  <div className={classnames(namespace, className)}>
    {propositions.map((proposition) => (
      <ValueProposition key={`${proposition.id}`} {...proposition} />
    ))}
  </div>
);

ValuePropositions.propTypes = {
  className: string,
  propositions: arrayOf(shape()).isRequired,
};

ValuePropositions.defaultProps = {
  className: null,
};

export default ValuePropositions;
