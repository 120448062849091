/* eslint-disable react/jsx-fragments */
import React from 'react';

import { string, bool, number } from 'prop-types';
import classnames from 'classnames';
import ItemFull from '../fulfillment/fulfillment';

const namespace = 'ui-row-item__promise';

const CHAR_WIDTH = 7;
const FULL_WIDTH = 50;

const ShippingPromise = ({
  className,
  id,
  promise_text,
  hasFulfillment,
  contentWidth,
}) => {
  const splitString = (promiseText) => {
    const strSplit = promiseText.split(' ');
    let firstPartLength = 0;
    let lastPartStart = 0;

    strSplit.forEach((it, index) => {
      firstPartLength += it.length;
      const firstPartWidth = firstPartLength * CHAR_WIDTH;
      if (firstPartWidth > contentWidth) {
        lastPartStart = index;
        return;
      }
      firstPartLength += 1; // Space char
    });

    return {
      first: strSplit.slice(0, lastPartStart),
      last: strSplit.slice(lastPartStart),
    };
  };

  const estimatedPromiseSize = promise_text ? promise_text.length * CHAR_WIDTH : 0;
  const shouldSplitText = estimatedPromiseSize > contentWidth;
  const { first, last } = splitString(promise_text);
  const fullDrops = (estimatedPromiseSize + FULL_WIDTH) > contentWidth;

  const newClassNamePromise = classnames(
    `${namespace}--${id}`,
    { [`${namespace}__text--splitted`]: shouldSplitText },
  );

  const constructInnerHTML = () => {
    const newClassName = classnames(
      `${namespace}__text`,
      { [`${namespace}__text--long`]: hasFulfillment && fullDrops },
      `${namespace}__text--last`,
    );

    if (shouldSplitText) {
      return (
        <React.Fragment>
          <span className={`${namespace}__text`}>{first.join(' ')}</span>
          <span className={`${namespace}__text ${namespace}__text--last`}>{last.join(' ')}</span>
        </React.Fragment>
      );
    }
    return (
      <span className={newClassName}>{promise_text}</span>
    );
  };

  return (
    <p className={classnames(namespace, newClassNamePromise, className)}>
      {constructInnerHTML()}
      {hasFulfillment && <ItemFull />}
    </p>
  );
};

ShippingPromise.propTypes = {
  className: string,
  hasFulfillment: bool.isRequired,
  id: string.isRequired,
  promise_text: string.isRequired,
  contentWidth: number.isRequired,
};

ShippingPromise.defaultProps = {
  className: null,
};

export default ShippingPromise;
