import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const namespace = 'ui-row-item__brand-discoverability';

const BrandDiscoverability = ({
  className,
  brand,
}) => (
  <span className={classnames(namespace, className)}>{brand}</span>
);

BrandDiscoverability.propTypes = {
  brand: string.isRequired,
  className: string,
};

BrandDiscoverability.defaultProps = {
  className: null,
};

export default BrandDiscoverability;
