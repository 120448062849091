/* eslint-disable react/jsx-fragments */
import React, { Children, cloneElement } from 'react';

import { bool, string, node } from 'prop-types';
import classnames from 'classnames';
import Separator from './separator';

const namespace = 'ui-row-item__group';

const Group = ({
  className,
  children,
  noSeparator,
}) => {
  if (children && (!Array.isArray(children) || (children.length > 0 && children.some(ch => !!ch)))) {
    return (
      <div className={classnames(namespace, className)}>
        {
          Children.map(children, (child) => {
            if (!child) {
              return null;
            }
            return (
              <React.Fragment>
                {!noSeparator && <Separator />}
                {cloneElement(child, { className: classnames(`${namespace}__element`, child.props.className) })}
              </React.Fragment>
            );
          })
        }
      </div>
    );
  }
  return null;
};

Group.propTypes = {
  children: node,
  className: string,
  noSeparator: bool,
};

Group.defaultProps = {
  className: null,
  children: null,
  noSeparator: false,
};

export default Group;
