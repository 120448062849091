import React from 'react';

import classnames from 'classnames';
import { Tag } from '@andes/tag';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../../link';
import { namespace, textIconCarouselPropTypes } from './constants';
/**
 * @typedef {Object} TextIconCarouselProps
 * @property {Object[]} tags - Los datos de las etiquetas que se mostrarán en el carrusel.
 * @property {string} tags[].id - El identificador único de la etiqueta.
 * @property {string} tags[].label - El texto de la etiqueta.
 * @property {string} tags[].url - El enlace asociado a la etiqueta.
 * @property {React.Component} Icon - El componente del ícono que se mostrará junto a la etiqueta.
 */

/**
 * Componente que renderiza un carrusel de etiquetas con íconos.
 *
 * @param {TextIconCarouselProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el carrusel de etiquetas con íconos.
 */
const TextIconCarousel = ({ tags, Icon }) => (
  <div className={classnames(namespace)}>
    <CarouselFree className={`${namespace}-carousel`}>
      {tags.map((tag) => (
        <CarouselFreeSlide key={tag.id} className={`${namespace}-item-slide`}>
          <Link className={`${namespace}__link`} href={tag.url} title={tag.label}>
            <Tag label={tag.label} asset={{ icon: <Icon /> }} />
          </Link>
        </CarouselFreeSlide>
      ))}
    </CarouselFree>
  </div>
);

TextIconCarousel.propTypes = {
  ...textIconCarouselPropTypes,
};

export default TextIconCarousel;
