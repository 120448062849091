/* eslint-disable react/no-unknown-property */
import React from 'react';

const namespace = 'ui-row-item__group__element-separator';

const Separator = () => (
  <hr noshade className={namespace} />
);

Separator.propTypes = {};

export default Separator;
