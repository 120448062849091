import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--star ui-search-icon--star-full';
const ICON_ID = 'poly_star_fill';

const IconStarFull = (props) => (
  <BuildIcon
    {...props}
    iconId={ICON_ID}
    viewBox="0 0 10 10"
    className={classnames(namespace, props.className)}
    color={props.color ?? '#3483fa'}
  />
);

IconStarFull.propTypes = {
  className: string,
  color: string,
  style: string,
};

export default IconStarFull;
