import React from 'react';
import { string, shape, bool } from 'prop-types';

import classnames from 'classnames';

import IconCockade from '../../../../../../components-v2/icons/sources/cockade';

const namespace = 'ui-row-official-store-label';

const OfficialStoreLabel = ({ className, label, icon }) => (
  <div className={`${namespace}__container`}>
    <p
      className={classnames(
        namespace,
        className,
        `ui-row-color--${label.color}`,
      )}
    >
      {label.text}
    </p>
    {icon && <IconCockade />}
  </div>
);

OfficialStoreLabel.propTypes = {
  className: string,
  icon: bool,
  label: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
};

OfficialStoreLabel.defaultProps = {
  className: null,
};

export default OfficialStoreLabel;
