import React from 'react';
import { string, shape, node } from 'prop-types';
import classnames from 'classnames';
import { getStyleProps } from '../utils/style-props';

const namespace = 'ui-row-styled-label';

const StyledLabel = ({ className, text, ...props }) => (
  <span
    className={
      classnames(
        namespace,
        className,
        {
          [`ui-row-size--${props.size}`]: !!props.size,
          [`ui-row-weight--${props.font_weight}`]: !!props.font_weight,
        },
      )
    }
    style={getStyleProps(props)}
  >
    {text}
  </span>
);

StyledLabel.propTypes = {
  className: string,
  props: shape({
    background: string,
    color: string,
    size: string,
    font_weight: string,
  }),
  text: node.isRequired,
};

StyledLabel.defaultProps = {
  className: null,
  props: {},
};

export default StyledLabel;
