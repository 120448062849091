
import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';

const namespace = 'ui-row-item__pds-options';

const PdsOptions = ({
  className,
  text,
}) => (
  <div className={classnames(namespace, className)}>
    {text}
  </div>
);

PdsOptions.propTypes = {
  className: string,
  text: string.isRequired,
};

PdsOptions.defaultProps = {
  className: null,
};

export default PdsOptions;
