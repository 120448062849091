import React, { useMemo } from 'react';
import { string, shape, number } from 'prop-types';
import classnames from 'classnames';
import Price from '../price';

const namespace = 'ui-row-installments';

const PRICE_FORMAT_SPECIFIER = '{price}';

const Installments = ({
  className,
  installments,
  country,
  currencies,
  price,
}) => {
  const { text, amount, currency_id, color, prefix_pill = '' } = installments;
  const priceIndex = text.indexOf(PRICE_FORMAT_SPECIFIER);
  const installmentsClassName = classnames(
    className,
    namespace,
    `ui-row-color--${color}`,
    { [`${namespace}-space`]: !!prefix_pill },
  );
  const showDecimals = amount && parseInt(amount.toFixed(2).split('.')[0], 10) < 1000;

  const buildPrefix = (text_label = '') => {
    if (text_label) {
      const arrayPrefix = text_label.split(' ');
      const prefix = arrayPrefix.map((item) => (
        <b className={`${namespace}-pill__section`} key={`${item}-pill`}>
          {item}
        </b>
      ));

      return prefix;
    }
    return null;
  };

  const prefixMemo = useMemo(() => buildPrefix(prefix_pill), [prefix_pill]);

  if (priceIndex > 0) {
    return (
      <span className={installmentsClassName}>
        {prefix_pill && (
          <span className={`${namespace}-pill`}>{prefixMemo}</span>
        )}
        {price.rebate_price && (
          <div className={`${namespace}-prefix`}>
            {price.rebate_price.amount && (
              <Price
                amount={price.rebate_price.amount}
                currency_id={currency_id}
                size="x-tiny"
                showDecimals={showDecimals}
                country={country}
                currencies={currencies}
              />
            )}
            <span>{price.rebate_price.text}</span>
          </div>
        )}
        {text.substring(0, priceIndex)}
        <Price
          amount={amount}
          currency_id={currency_id}
          size="x-tiny"
          className={`ui-row-color--${color}`}
          showDecimals={showDecimals}
          country={country}
          currencies={currencies}
        />
        {text.substring(priceIndex + PRICE_FORMAT_SPECIFIER.length).trim()}
      </span>
    );
  }

  return (
    <div className={installmentsClassName}>
      {prefix_pill && <span className={`${namespace}-pill`}>{prefixMemo}</span>}
      <span>{text}</span>
    </div>
  );
};

Installments.propTypes = {
  className: string,
  installments: shape({
    text: string.isRequired,
    amount: number,
    currency_id: string,
    color: string.isRequired,
  }).isRequired,
  price: shape({}).isRequired,
};

Installments.defaultProps = {
  className: null,
};

export default Installments;
