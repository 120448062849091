/* eslint-disable react/jsx-fragments */
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import PricePart from './price-part';
import IconLoyalty from '../../../../../../components-v2/icons/sources/loyalty';

const namespace = 'ui-row-price';

const { bool, string, number, oneOf, shape } = PropTypes;

const Price = ({
  bold,
  className,
  decimalSeparator,
  original_price,
  size,
  originalPriceSize,
  currency_id,
  thousandSeparator,
  amount,
  discount_label,
  discountSource,
  from,
  showDecimals,
  showOriginalValue,
  country,
  bulksale,
}) => {
  const { currencies } = country;
  const { symbol } = currencies[currency_id];
  const defaultThousandSeparator = country.thousands_separator;
  const defaultDecimalSeparator = country.decimal_separator;
  const computedThousandSeparator = thousandSeparator || defaultThousandSeparator;
  const computedDecimalSeparator = decimalSeparator || defaultDecimalSeparator;

  return (
    <div
      className={classNames(namespace, `${namespace}--size-${size}`, className)}
    >
      {showOriginalValue && (
        <PricePart
          className={`${namespace}__original-value`}
          value={bulksale ? bulksale.original_price : original_price}
          thousandSeparator={computedThousandSeparator}
          decimalSeparator={computedDecimalSeparator}
          currencySymbol={symbol}
          showDecimals={showDecimals}
          strikeThrough
          size={originalPriceSize}
        />
      )}
      {from && <div className={`${namespace}__front-price`}>{from.text}</div>}
      <div
        className={classNames(`${namespace}__second-line`, {
          [`${namespace}__second-line--bold`]: bold,
        })}
      >
        <PricePart
          value={bulksale ? bulksale.price : amount}
          thousandSeparator={computedThousandSeparator}
          decimalSeparator={computedDecimalSeparator}
          currencySymbol={symbol}
          showDecimals={showDecimals}
          size={size}
          bulksale={bulksale}
        />
        {(discountSource || discount_label) && (
          <span className={classNames(`${namespace}__second-line__label`)}>
            {discountSource === 'loyalty' && <IconLoyalty hidden />}
            {discount_label && (
              <React.Fragment>
                {discount_label.prefix && (
                  <span className={`${namespace}__discount_prefix`}>
                    {discount_label.prefix}
                  </span>
                )}
                <span className={`${namespace}__discount`}>
                  {discount_label.text}
                </span>
              </React.Fragment>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

const sizesOneOf = oneOf(['x-tiny','tiny', 'small', 'medium', 'large'])

Price.propTypes = {
  amount: number.isRequired,
  bold: bool,
  bulksale: shape({}),
  className: string,
  currency_id: string.isRequired,
  decimalSeparator: string,
  discount_label: shape({ text: string }),
  discountSource: string,
  from: shape({ text: string }),
  original_price: number,
  originalPriceSize: sizesOneOf,
  showDecimals: bool,
  size: sizesOneOf,
  thousandSeparator: string,
};

Price.defaultProps = {
  bold: false,
  bulksale: null,
  className: null,
  discountLabel: null,
  from: null,
  original_price: null,
  size: 'medium',
  thousandSeparator: null,
  decimalSeparator: null,
  discount_label: null,
  discountSource: null,
  showDecimals: true,
};

export default Price;
